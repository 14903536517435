@import "fonts";
@import "libs";
@import "vars";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }
body input:required:valid,
body textarea:required:valid {
	color: #666; }

html, body {
	height: 100%;
	background-color: #fff; }

body {
	min-width: 320px;
	font-weight: 300;
	position: relative;
	line-height: 1.5;
	font-family: $text-font;
	font-size: 14px;
	color: $light2-grey-color;
	overflow-x: hidden;
	opacity: 1; }

h1,h2,h3,h4,h5,h6 {
	text-transform: uppercase;
	font-family: $heading-font;
	font-weight: 500;
	margin-top: 0;
	margin-bottom: 15px;
	color: $black-color; }
h1 {
	line-height: 1.1; }

h2 > a {
	text-decoration: none;
	color: inherit; }

button,
a {
	outline: none;
	border-radius: 0;
	cursor: pointer;
	transition: .2s all; }

input,
textarea {
	outline: none;
	border: 1px solid #d4d5d5;
	padding: 4px 15px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	transition: .2s all;
	font-size: 13px;
	&:focus {
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.075); } }
fieldset {
	border: none;
	padding: 0;
	margin: 0; }

ul {
	&.list-unstyled,
	&.list-inline {
		list-style: none;
		margin: 0;
		padding: 0; }
	&.list-inline {
		li {
			display: inline-block;
			& + li {
				margin-left: 7px; } } } }
a {
	color: $light2-grey-color;
	text-decoration: underline;
	&:hover {
		color: $light2-grey-color;
		text-decoration: none; } }

.btn {
	text-decoration: none;
	background: none;
	border: none;
	outline: none;
	display: inline-block;
	padding: 4px 15px;
	border-radius: 20px;
	text-transform: uppercase;
	font-family: $heading-font;
	&-default {
		background-color: $white-button-color;
		color: $red-color;
		border: 1px $red-color solid; }
	&-red, &-primary {
		background-color: $red-color;
		color: $white-button-color;
		&:hover {
			background-color: lighten($red-color, 8%);
			color: $white-button-color; } }
	&-red-border {
		border: 1px solid $red-color;
		&:hover {
			background-color: $red-color;
			color: $white-button-color; } }
	&-grey {
		background-color: $light2-grey-color;
		color: $white-button-color;
		&:hover {
			background-color: lighten($light2-grey-color, 8%);
			color: $white-button-color; } }
	span + .fa {
		margin-left: 7px;
		padding-left: 7px;
		border-left: 1px solid; } }

.btn-group {
	.btn {
		border-radius: 0;
		&:first-child {
			border-radius: 20px 0 0 20px; }
		&:last-child {
			border-radius: 0 20px 20px 0; }
		& + .btn.btn-red {
			border-left: 1px solid lighten($red-color, 10%); }
		& + .btn.btn-grey {
			border-left: 1px solid lighten($light2-grey-color, 10%); } } }

.text-danger {
	color: $red-color;
	font-size: 14px; }

.text-right {
	text-align: right; }

.nowrap {
	white-space: nowrap; }

.alert {
	margin-bottom: 20px;
	border: 1px $light1-grey-color solid;
	padding: 15px 35px 15px 15px;
	position: relative;
	background-color: $white-button-color;
	button {
		background: none;
		border: none;
		position: absolute;
		right: 5px;
		top: 5px; }
	&-danger {
		border-color: $red-color; } }

.table {
	width: 100%;
	margin-bottom: 20px;
	&.table-bordered {
		td {
			padding: 5px;
			background-color: $white-button-color; }
		tr:nth-child(even) {
			td {
				background-color: lighten($light1-grey-color, 20%); } }
		thead {
			td {
				font-weight: 500; } } } }

.header-menu {
	background-color: #fff;
	position: relative;
	z-index: 2;
	box-shadow: 0px 2px 2px lighten($light1-grey-color, 20%);
	padding: 10px 0;
	margin-bottom: 15px; }

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	> div {
		text-align: center; }
	&-logo {
		width: 130px;
		img {
			max-width: 100%; } }
	&-phone,
	&-regim {
		display: flex;
		align-items: center;
		color: $black-color;
		font-size: 15px;
		font-weight: 500;
		.fa {
			margin-right: 15px;
			font-size: 20px; } }
	&-phone {
		a {
			text-decoration: none;
			color: $black-color; } }
	&-callback {
		margin-bottom: 7px; }
	&-search {
		&-form {
			border-radius: 20px;
			border: 1px solid $red-color;
			padding-left: 5px;
			margin-bottom: 7px;
			input, button {
				border: none;
				background: none; }
			input {
				font-size: 12px;
				border: none;
				box-shadow: none; }
			button {
				background-color: $red-color;
				color: $white-button-color;
				border-radius: 0 20px 20px 0;
				padding: 4px 10px;
				&:hover {
					background-color: lighten($red-color, 8%);
					color: $white-button-color; } } } }
	&-profile {
		a {
			text-decoration: none;
			&:hover {
				border-bottom: 1px solid; } } }
	&-cart {
		a {
			text-decoration: none;
			&:hover {
				.fa-shopping-cart {
					color: $black-color; } } }
		.fa-shopping-cart {
			font-size: 25px;
			margin-right: 7px;
			color: $red-color; } } }


.main-menu {
	.container > ul {
		display: flex;
		justify-content: space-around;
		> li {
			position: relative;
			z-index: 2;
			> a {
				text-decoration: none;
				text-transform: uppercase;
				font-size: 18px;
				font-family: $heading-font;
				color: $black-color;
				display: inline-block;
				padding: 2px 12px;
				border-radius: 20px;
				&:hover, &.active {
					background-color: $light1-grey-color;
					color: $white-button-color; } }
			&:hover {
				ul {
					display: block; }
				> a {
					background-color: $light1-grey-color;
					color: $white-button-color; } }
			> ul {
				display: none;
				position: absolute;
				z-index: 2;
				padding: 15px;
				border-radius: 20px;
				background-color: $white-button-color;
				box-shadow: 0px 2px 2px lighten($light1-grey-color, 20%);
				li {
					& + li {
						margin-top: 3px; }
					a {
						display: block;
						white-space: nowrap;
						text-decoration: none;
						&:hover {
							text-decoration: underline; } } } } } } }

.module-product-block {
	margin-bottom: 20px;
	h2 {
		text-align: center;
		font-size: 24px; } }

#column-left {
	.module-block,
	.module-product-block {
		margin-bottom: 15px;
		h2 {
			text-align: left;
			font-size: 22px;
			line-height: 22px;
			margin-bottom: 5px; }
		.product-card-grid {
			display: block;
			.product-card {
				width: auto;
				margin-bottom: 25px;
				.name {
					height: auto; } } } } }

.product-card {
	margin-bottom: 15px;
	.image {
		margin-bottom: 4px;
		a {
			display: block;
			text-align: center;
			img {
				max-width: 100%; } } }
	h3.name {
		margin: 0 0 7px 0;
		height: 75px;
		text-align: center;
		overflow: hidden;
		a {
			text-decoration: none;
			color: $black-color;
			&:hover {
				border-bottom: 1px solid; } } }
	.stock {
		font-size: 13px;
		margin-bottom: 7px;
		text-align: center; }

	.price {
		font-family: $heading-font;
		font-size: 19px;
		margin-bottom: 7px;
		text-align: center;
		color: $black-color;
		&-old {
			color: $light1-grey-color;
			font-size: 15px;
			text-decoration: line-through; } }
	.buttons {
		text-align: center;
		&-cart {
			margin-bottom: 7px; } } }

.owl-carousel {
	.product-card {
		max-width: 260px; } }

.product-card-grid {
	.product-card {
		.description {
			display: none; } } }

.product-card-list {
	.product-card-block {
		width: 100%;
		float: none; }
	.product-card {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.image {
			width: 20%;
			padding: 2px;
			img {
				max-width: 100%; } }
		.name-description-stock {
			width: 50%;
			.name {
				height: auto; } }
		.price-buttons {
			width: 27%; }
		.description {
			margin-bottom: 5px;
			font-size: 13px;
			color: $light2-grey-color; }
		.stock {
			color: $light1-grey-color;
			text-align: left; }
		.price {
			margin-bottom: 7px;
			> span {
				display: block;
				text-align: center; } } } }

.sidebar-category-list {
	margin-bottom: 20px;
	li {
		a {
			text-decoration: none;
			font-family: $heading-font;
			font-size: 18px;
			&.active,
			&:hover {
				color: $black-color;
				border-bottom: 1px solid; } } } }

.panel-manage-products {
	margin-bottom: 20px;
	padding: 10px 0;
	.display-button {
		button {
			border: none;
			background: none;
			padding: 0;
			display: inline-block;
			vertical-align: middle;
			.fa {
				font-size: 23px;
				line-height: 1;
				color: $dark1-grey-color; }
			+ button {
				margin-left: 7px; }
			&.active {
				.fa {
					color: $red-color; } } } }
	.sort-limit {
		> * {
			display: inline-block;
			vertical-align: bottom;
			select {
				max-width: 150px; } }
		.limit {
			margin-left: 10px; } }
	.compare {
		text-align: right;
		a {
			display: inline-block;
			vertical-align: middle; } } }

ul.pagination {
	margin: 0;
	padding: 0;
	list-style-type: none;
	li {
		display: inline-block;
		+ li {
			margin-left: 7px; }
		a, span {
			display: inline-block;
			font-family: $heading-font;
			font-size: 18px;
			color: $black-color;
			text-decoration: none; }
		a:hover {
			border-bottom: 1px solid; }
		span {
			color: $light1-grey-color; } } }

.footer {
	background-color: $dark1-grey-color;
	color: $light1-grey-color;
	padding: 30px 0;
	margin-top: 15px;
	a {
		color: $light1-grey-color; }
	h4 {
		margin: 0 0 10px 0;
		font-size: 18px;
		color: $light1-grey-color; }
	ul li a {
		text-transform: uppercase;
		text-decoration: none;
		&:hover {
			border-bottom: 1px solid; } }

	&-bottom {
		margin-top: 10px;
		.container {
			display: table;
			> div {
				display: table-cell;
				vertical-align: middle; } }
		.powered {
			text-transform: uppercase;
			font-size: 15px;
			font-family: $heading-font; }
		.icons {
			text-align: right; } } }

.mfp-ajax-holder .mfp-content {
	max-width: 600px;
	width: 100%;
	background-color: #fff; }
.wrapper-popup-form {
	> div {
		padding: 15px;
		text-align: center;
		p {
			text-align: left; } }
	h2 {
		text-align: center;
		color: $black-color; }
	.buttons {
		margin-top: 15px; }
	.compare-info {
		padding: 30px; } }

.form-group {
	&.required {
		label {
			&:after {
				content: '*';
				color: $red-color;
				margin-left: 3px; } } }
	label {
		display: block;
		margin-bottom: 3px; }
	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="password"],
	textarea {
		display: block;
		width: 100%; }
	& + & {
		margin-top: 10px; } }

.breadcrumb {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-bottom: 15px;
	li {
		display: inline-block;
		font-size: 1.3rem;
		+ li {
			margin-left: 4px;
			&:before {
				content: '';
				font-family: fontawesome;
				margin-right: 4px;
				font-size: 1rem; } }
		a {
			border-bottom: 1px solid;
			font-size: 12px;
			text-decoration: none;
			&:hover {
				border-bottom: none; } }
		&:first-child {
			a {
				border-bottom: none; } } } }

.flycart {
	&-heading {
		h3 {
			margin-bottom: 15px; }
		p {
			margin: 0;
			margin-bottom: 15px;
			b {
				color: $black-color; } } }

	.products-item {
		border-top: 1px #E8E6E9 solid;
		padding-top: 10px;
		margin-bottom: 10px;
		.image {
			text-align: center;
			img {
				max-width: 100%; } }
		.name {
			margin-bottom: 2px;
			text-align: left;
			a {
				text-decoration: none;
				text-transform: uppercase;
				font-family: $heading-font;
				color: $black-color;
				&:hover {
					border-bottom: 1px solid; } } }
		.stock,
		.quantity {
			color: $light2-grey-color; }
		.stock {
			text-align: left; }
		.total {
			white-space: nowrap; } }

	&-buttons {
		border-top: 1px #E8E6E9 solid;
		padding-top: 15px;
		display: flex;
		justify-content: space-between; } }

.product-info {
	.main-image {
		margin-bottom: 10px;
		a {
			display: block;
			img {
				max-width: 100%; } } }
	.image-additional-item {
		a {
			display: block;
			text-align: center;
			border-top: 1px solid transparent;
			padding-top: 5px;
			&.active {
				border-top: 1px solid $red-color; } } }

	.find-cheaper {
		margin-bottom: 10px;
		a {
			text-decoration: none; } }

	.product-meta {
		margin-bottom: 10px;
		.line {
			span {
				margin-right: 5px;
				font-weight: 400; } } }
	.right-panel .price {
		margin-bottom: 10px;
		font-family: $heading-font;
		font-size: 30px;
		color: $red-color;
		&-old {
			font-size: 18px;
			text-decoration: line-through;
			color: $light1-grey-color; } }

	.product-action-one {
		margin-bottom: 15px;
		> div {
			display: inline-block; }
		input {
			width: 50px;
			text-align: center; }
		button.plus, button.minus {
			border: none;
			background: none; }
		.button-cart {
			margin-left: 10px; } }

	.product-action-two {
		margin-bottom: 15px; }

	.tags {
		margin-top: 15px;
		margin-bottom: 15px;
		ul {
			li {
				display: inline-block;
				margin-top: 5px;
				margin-right: 5px; } } }

	.product-info-mobile-images {
		margin-bottom: 20px; } }

.product-review {
	h4 {
		font-size: 18px; }
	img {
		margin-top: 5px; }
	.buttons {
		margin-top: 15px; }
	.alert-danger {
		margin-top: 15px; } }

.captcha-field {
	img {
		margin-top: 5px; } }

.contact-page-contacts {
	margin-bottom: 20px;
	.line {
		.icon {
			float: left;
			.fa {
				font-size: 16px;
				width: 25px;
				line-height: 25px;
				text-align: center; }
			+ .content {
				margin-left: 30px; } }
		+ .line {
			margin-top: 15px; } } }

.reviews-list-block {
	margin-bottom: 20px;
	.reviews-list {
		&-item {
			margin-bottom: 20px;
			.top {
				display: table;
				width: 100%;
				margin-bottom: 10px;
				> div {
					display: table-cell;
					vertical-align: middle;
					width: 50%; }
				.author-date {
					text-align: right;
					padding-right: 10px;
					span {
						font-weight: 500; } }
				.rating {
					color: $red-color; } }
			.message-wrap {
				text-align: center;
				.message {
					font-style: italic;
					display: inline-block;
					padding: 15px 20px;
					position: relative;
					&:before,
					&:after, {
						position: absolute;
						font-family: fontawesome;
						color: #666;
						font-size: 13px; }
					&:before {
						content: '';
						top: 0;
						left: 0; }
					&:after {
						content: '';
						bottom: 0;
						right: 0; } } } } } }

.slideshow {
	margin-top: -15px;
	margin-bottom: 15px; }

.owl-carousel {
	background: none;
	.owl-wrapper-outer {
		background: none;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-align: center; }
	.owl-item {
		text-align: center;
		.product-card {
			display: inline-block; } }
	.owl-nav, .owl-prev, .owl-next {
		position: absolute; }
	.owl-nav {
		width: 100%;
		top: 50%;
		.owl-prev, .owl-next {
			.fa {
				font-size: 20px; }
			&.disabled {
				opacity: .3;
				cursor: default; } }
		.owl-prev {
			left: 0; }
		.owl-next {
			right: 0; } }
	&.product-info-mobile-images {
		.owl-dots {
			top: 10px; } } }

.product-description {
	margin-top: 15px; }

iframe {
	width: 100%!important; }

.product-attribute {
	table {
		width: 100%;
		tr {
			td {
				width: 49%;
				padding: 2.5px 0;
				font-size: 13px;
				&:first-of-type {
					font-family: $heading-font;
					font-size: 14px;
					font-weight: 300; }
				&:last-of-type {
					text-align: center; } }
			+ tr {
				border-top: 1px lighten($light1-grey-color, 30%) solid; } } } }

.panel {
	margin-bottom: 20px; }

.simplecheckout-cart {
	margin-bottom: 20px;
	&-products {
		.item {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			border-bottom: 1px lighten($light1-grey-color, 20%) solid;
			padding-bottom: 15px;
			> * {
				padding: 5px; }
			.product {
				width: 50%;
				.image {
					float: left; }
				.meta {
					margin-left: 120px;
					.name {
						margin-bottom: 5px;
						a {
							text-decoration: none;
							font-family: $heading-font;
							color: $black-color;
							font-size: 16px;
							&:hover {
								border-bottom: 1px solid; } } }
					.model {
						margin-bottom: 10px;
						font-size: 13px; }
					.price {
						color: $red-color;
						font-size: 18px;
						font-family: $heading-font;
						span {
							color: lighten($light1-grey-color, 20%);
							font-size: 13px; } } } }
			.quantity,
			.total,
			.remove {
				text-align: center; }
			.quantity {
				width: 30%;
				white-space: nowrap;
				button {
					background: none;
					border: none; }
				input {
					width: 40px;
					text-align: center; } }
			.total {
				width: 15%;
				font-family: $heading-font;
				font-size: 18px;
				white-space: nowrap;
				color: $black-color;
				span {
					font-weight: 300;
					font-size: 13px;
					color: $light1-grey-color; } }
			.remove {
				width: 5%;
				button {
					background: none;
					border: none;
					.fa {
						font-size: 18px;
						color: $red-color; } } } } }
	&-totals {
		display: table;
		width: 100%;
		font-family: $heading-font;
		> div {
			display: table-row;
			> span {
				display: table-cell;
				padding: 5px;
				&:first-child {
					text-align: right;
					width: 85.5%; }
				&:last-of-type {
					white-space: nowrap;
					color: $black-color;
					font-size: 17px; } } }
		#total_total {
			font-size: 16px;
			span {
				&:last-of-type {
					color: $red-color;
					font-size: 19px;
					font-weight: 600; } } } } }

#simplecheckout_shipping_address,
#simplecheckout_comment {
	margin-top: 15px; }
#agreement_checkbox {
	margin-bottom: 10px; }

#simplecheckout_shipping {
	b {
		font-family: $heading-font; } }

.seotext {
	margin-bottom: 20px; }

.scroll-to-top {
	position: fixed;
	bottom: 10px;
	right: 15px;
	display: none;
	z-index: 1; }

div.table-responsive {
	overflow-x: auto; }

.compare-widget {
	a {
		position: fixed;
		text-decoration: none;
		right: 0;
		top: 25%;
		background-color: #fff;
		opacity: .6;
		box-shadow: 0 1px 3px #cecece;
		border: 1px $red-color solid;
		border-right: none;
		padding: 5px 7px;
		z-index: 1;
		.fa, span {
			display: block;
			text-align: center; }
		.fa {
			margin-bottom: 7px;
			font-size: 20px; }
		&:hover {
			opacity: 1; } } }

.text-checkout {
	margin-bottom: 20px;
	p {
		padding: 0;
		margin: 0; } }

.yandex_delivery_icon {
	width: 50%;
	margin: 5px auto;
	img {
		max-width: 100%; } }



@import "media"; // Всегда в конце
