@import "vars";

// @media only screen and (max-width : 1200px)
@media only screen and (max-width : 992px) {
	.header {
		flex-wrap: wrap;
	 .header-cart-search {
			width: 100%;
			margin-top: 15px;
			.header-search-form {
				display: inline-block; } } }
	.main-menu {
		padding-top: 10px;
		padding-bottom: 10px;
		.container > ul {
			> li {
				> a {
					font-size: 16px; } } } } }
@media only screen and (max-width: 768px) {
	h1 {
		font-size: 20px;
		margin-bottom: 10px; }
	.breadcrumb {
		margin-bottom: 7px; }
	.header {
		align-items: flex-start;
		padding-top: 10px;
		padding-bottom: 0;
		&-logo {
			order: 1;
			width: 100px;
			img {
				max-width: 100%;
				max-height: 100%; } }
		&-phone, &-regim {
			font-size: 14px;
			margin-top: 7px;
			font-weight: normal;
			.fa {
				margin-right: 10px; } }
		&-phone {
			order: 4; }
		&-regim {
			order: 5; }
		&-callback-auth {
			order: 2; }
		.header-cart-search {
			width: auto;
			margin-top: 0;
			order: 3;
			.cart-total {
				display: block; } } }
	.main-menu {
		padding-top: 5px;
		padding-bottom: 3px;
		.header-search {
			float: right; }
		.container {
			> ul {
				display: none;
				padding: 0;
				margin: 0;
				margin-top: 10px;
				> li {
					> a {
						padding: 0;
						border-radius: 0;
						line-height: 1.1;
						margin-bottom: 5px;
						&:hover, &.active {
							background: none;
							font-weight: 500;
							color: $black-color;
							border-bottom: 1px solid; } }
					> ul {
						display: block;
						position: static;
						min-width: auto;
						width: 100%;
						padding: 0;
						margin-left: 7px;
						box-shadow: none;
						margin-bottom: 5px;
						li {
							& + li {
								margin-top: 0; } } } } } } }
	.slideshow-wrap {
		display: none; }
	.product-card {
		width: auto;
		h3.name {
			height: auto; } }
	img {
		max-width: 100%; }

	.btn-group {
		.btn {
			font-size: 13px;
			padding-left: 10px;
			padding-right: 10px; } }

	.flycart {
		.col-xs-2, .col-xs-8 {
			width: 100%; }
		.image {
			text-align: center;
			margin-bottom: 7px; }
		&-buttons {
			display: block;
			> div {
				display: block;
				text-align: center;
				&:last-of-type {
					text-align: center;
					margin-top: 15px; } } } }
	.simplecheckout-cart-products {
		.item {
			display: block;
			.product {}
			.total,
			.remove {
				display: inline-block;
				vertical-align: middle; }
			.product {
				width: 100%; }
			.quantity,
			.total {
				width: auto;
				margin-left: 110px;
				text-align: left; }
			.total {
				span {
					display: inline; } } } }
	.table {
		thead {
			td {
				text-align: center; } } }

	.footer {
		padding-top: 15px;
		padding-bottom: 15px;
		h4 {
			margin-bottom: 0; }
		p {
			margin: 0; }
		.footer-top > div > div > div {
			margin-bottom: 10px; } }
	.compare-info {
		.buttons {
			a {
				margin-top: 10px; } } } }
