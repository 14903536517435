@import url('https://fonts.googleapis.com/css?family=Cuprum:400,400i,700,700i|Ubuntu:300,300i,400,400i,500,500i,700,700i&subset=cyrillic,cyrillic-ext,latin-ext');
@import "_mixins/font-face";

@include font-face("opensans", "../fonts/opensans/opensans-light", 300);
@include font-face("opensans", "../fonts/opensans/opensans-lightitalic", 300, 'italic');
@include font-face("opensans", "../fonts/opensans/opensans-regular", 400);
@include font-face("opensans", "../fonts/opensans/opensans-italic", 400, 'italic');
@include font-face("opensans", "../fonts/opensans/opensans-semibold", 600);
@include font-face("opensans", "../fonts/opensans/opensans-semibolditalic", 600, 'italic');
@include font-face("opensans", "../fonts/opensans/opensans-bold", 800);
@include font-face("opensans", "../fonts/opensans/opensans-bolditalic", 800, 'italic');

@include font-face("roboto", "../fonts/roboto/roboto-thin", 100);
@include font-face("roboto", "../fonts/roboto/roboto-light", 300);
@include font-face("roboto", "../fonts/roboto/roboto-regular");
@include font-face("roboto", "../fonts/roboto/roboto-medium", 500);
@include font-face("roboto", "../fonts/roboto/roboto-bold", 700);
@include font-face("roboto", "../fonts/roboto/roboto-black", 800);
